<template>
  <App
    title="创建金融单"
    left-arrow
  >
    <FinancialOrder
      ref="financialOrder"
      :orderNum="orderNum"
    />
    <Footer
      slot="footer"
      @on-save="onSave"
      @on-cancel="$page.close()"
    />
  </App>
</template>
<script>
import FinancialOrder from '_c/business/financialOrder'
import Footer from '_c/business/footer'
import { addloanorder } from '@/api/order'
import { Toast } from 'vant'

Toast.allowMultiple()

export default {
  name: 'createFinancialOrder',
  components: {
    FinancialOrder,
    Footer
  },
  data () {
    return {
      orderNum: ''
    }
  },
  methods: {
    init () {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner'
      })
      this.$refs.financialOrder.init().finally(() => {
        Toast.clear()
      })
    },
    onSave () {
      this.$refs.financialOrder.validate().then(data => {
        if (!data) return

        const userInfo = this.$storage.get('userInfo')
        const params = {
          channel: this.$storage.get('channel'),
          creatorId: userInfo.idmUserid,
          creatorName: userInfo.name,
          orderNum: this.orderNum,
          fiCode: data.fiCode,
          fiOrgCode: data.fiOrgCode,
          cityId: data.cityId,
          downPaymentRate: data.downPaymentRate,
          balancePaymentRate: data.balancePaymentRate,
          nper: data.nper,
          theLenderType: data.theLenderType,
          theLender: data.theLenderType === '0' ? data.theLender : data.enterpriseName,
          theLenderCertificate: data.theLenderType === '0' ? data.theLenderCertificate : data.enterpriseCode,
          theLenderPhone: data.theLenderPhone,

          downPaymentAmount: data.downPaymentAmount,
          balancePaymentAmount: data.balancePaymentAmount,
          loanPaymentRate: data.loanPaymentRate,
          loanPaymentAmount: data.loanPaymentAmount,
          monthPaymentAmount: data.monthPaymentAmount
        }
        this.$requestHandler(addloanorder, params).then(res => {
          if (res.success) {
            Toast('创建成功!')
            this.$router.go(-1)
          } else {
            Toast(res.msg || '创建失败!')
          }
        })
      })
    }
  },
  mounted () {
    const pageParam = this.$route.query || {}
    this.orderNum = pageParam.orderNum
    this.$nextTick(() => {
      this.init()
    })
  }
}
</script>